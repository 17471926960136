<template>
  <section class="iq-about-03 overview-block-ptb" id="about-us">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-title iq-mb-20">
            <h2 class="title iq-tw-6">About Our App</h2>
            <div class="divider"></div>
            <div class="center-block iq-pt-30">
              <span class="lead iq-tw-6"
                >Climate action enabler through collecting micro-contribution
                from society. We believe that it is a powerful way to fight
                climate crisis.
                <p>Drops make an ocean!</p></span
              >
            </div>
          </div>
        </div>
        <div class="col-sm-12 text-center">
          <p class="text-justify">
            Our Story - Online shopping, which is now a lifestyle and the
            quantity is increasing due to the COVID-19 pandemic, can actually
            accelerate climate change. Online activities, which amounted to 98.3
            million online transactions, an increase of 18.1% compared to before
            the COVID-19 pandemic recorded in March 2020 in Indonesia (Bank
            Indonesia, 2020) can actively contribute to accelerating climate
            change through the carbon footprint generated from energy use for
            shipping and packaging waste. A large number of packaging will
            become waste and the more types of transportation used - boats,
            planes, cars, motorbikes - until the goods arrive at their
            destination, produce large carbon emissions.
          </p>
          <p class="text-justify">
            Just imagine, if the entire transaction above requires packaging
            with a weighing of 0.5 kg and has a delivery distance of 760 Km
            (Shipping from Jakarta to Surabaya), then the carbon footprint
            spread over the earth is 51.44 Billion Kg CO2e.
          </p>
          <div class="btn-group iq-mt-40" role="group" aria-label="">
            <!-- <a class="button iq-mr-15" href="javascript:void(0)">
              <i class="ion-social-apple"></i> App Store</a
            >
            <a class="button iq-mr-15" href="javascript:void(0)">
              <i class="ion-social-android"></i> Play Store</a
            > -->
            <a class="button iq-mr-15" :href="requestDemoUrl" target="_blank">
              <i class="ion-android-send"></i> Request Demo</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "About",
  components: {},
  data() {
    return {
      requestDemoUrl: process.env.VUE_APP_APPS_URL + "/request-demo",
    };
  },
};
</script>
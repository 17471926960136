<template>
  <section class="overview-block-ptb white-bg" id="faq">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-title">
            <h2 class="title iq-tw-6">Frequently Asked Questions</h2>
            <div class="divider"></div>
            <p>
              If you got any query? Here you go! Don't hesitate to ask any
              Question! We are happy to help with your questions. Here you go!
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <img
            class="img-responsive center-block"
            src="assets/images/01.png"
            alt=""
          />
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="iq-accordion iq-mt-50">
            <div class="iq-ad-block ad-active">
              <a
                href="javascript:void(0)"
                class="ad-title iq-tw-6 iq-font-grey"
              >
                <span class="ad-icon"
                  ><i
                    class="ion-ios-infinite-outline"
                    aria-hidden="true"
                  ></i></span
                >What is Carbon Footprint?</a
              >

              <div class="ad-details">
                <div class="row">
                  <!-- <div class="col-sm-3">
                    <img
                      alt="#"
                      class="img-responsive"
                      src="assets/images/faq-1.jpg"
                    />
                  </div> -->
                  <div class="col-sm-12">
                    A carbon footprint is the total greenhouse gas emissions
                    caused by an individual, event, organization, service, or
                    product, expressed as carbon dioxide equivalent.
                  </div>
                </div>
              </div>
            </div>

            <div class="iq-ad-block">
              <a
                href="javascript:void(0)"
                class="ad-title iq-tw-6 iq-font-grey"
              >
                <span class="ad-icon"
                  ><i class="ion-ios-time-outline" aria-hidden="true"></i></span
                >What is Carbon Offset?</a
              >
              <div class="ad-details">
                Carbon Offset is about a way to reduce carbon emissions in the
                atmosphere in equal proportion to our production. When we
                offset, we fund projects that avoid, reduce, or remove carbon
                emissions from the atmosphere such as forest plantation and
                community-based renewable energy projects.
              </div>
            </div>
            <div class="iq-ad-block">
              <a
                href="javascript:void(0)"
                class="ad-title iq-tw-6 iq-font-grey"
              >
                <span class="ad-icon"
                  ><i
                    class="ion-ios-compose-outline"
                    aria-hidden="true"
                  ></i></span
                >How do you measure carbon footprint & carbon offset?</a
              >
              <div class="ad-details">
                <div class="row">
                  <div class="col-sm-12">
                    We use a formula that is recommended by UNFCCC and IPCC to
                    measure carbon footprint and carbon offset.
                  </div>
                  <!-- <div class="col-sm-3">
                    <img
                      alt="#"
                      class="img-responsive"
                      src="assets/images/faq-1.jpg"
                    />
                  </div> -->
                </div>
              </div>
            </div>
            <div class="iq-ad-block">
              <a
                href="javascript:void(0)"
                class="ad-title iq-tw-6 iq-font-grey"
              >
                <span class="ad-icon"
                  ><i class="ion-ios-loop" aria-hidden="true"></i></span
                >Will my funds 100% distributed to project?</a
              >
              <div class="ad-details">
                We took 10% commission fee per each carbon offset transaction.
                Like other organizations, Carbon Addons has monthly operating
                costs. This includes team costs, technology development,
                infrastructure, marketing costs, and other overheads. With this
                model we can focus on developing technology to make it easier
                for you to contribute to our society and earth.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

 <script>
import $ from "jquery";
export default {
  components: {},
  mounted() {
    $(".iq-accordion .iq-ad-block .ad-details").hide();
    $(".iq-accordion .iq-ad-block:first")
      .addClass("ad-active")
      .children()
      .slideDown("slow");
    $(".iq-accordion .iq-ad-block").on("click", function () {
      if ($(this).children("div").is(":hidden")) {
        $(".iq-accordion .iq-ad-block")
          .removeClass("ad-active")
          .children("div")
          .slideUp("slow");
        $(this).toggleClass("ad-active").children("div").slideDown("slow");
      }
    });
  },
};
</script>

<template>
  <header id="header-wrap" data-spy="affix" data-offset-top="55">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <nav class="navbar navbar-default">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
              <button
                type="button"
                class="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                aria-expanded="false"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand" href="javascript:void(0)">
                <img src="assets/images/logo.png" alt="logo" />
              </a>
            </div>
            <!-- Collect the nav links, forms, and other content for toggling -->
            <div
              class="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul class="nav navbar-nav navbar-right" id="top-menu">
                <li class="active" @click="clickEvent()">
                  <a
                    href="#iq-home"
                    @click="clickEvent('click', 'Home')"
                    v-smooth-scroll
                    >Home</a
                  >
                </li>
                <li>
                  <a
                    href="#about-us"
                    @click="clickEvent('click', 'About Us')"
                    v-smooth-scroll
                    >About Us</a
                  >
                </li>
                <li>
                  <a
                    href="#features"
                    @click="clickEvent('click', 'Features')"
                    v-smooth-scroll
                    >Features</a
                  >
                </li>
                <li>
                  <a
                    href="#screenshots"
                    @click="clickEvent('click', 'Screenshots')"
                    v-smooth-scroll
                    >Screenshots</a
                  >
                </li>
                <li>
                  <a
                    href="#team"
                    @click="clickEvent('click', 'Team')"
                    v-smooth-scroll
                    >Team</a
                  >
                </li>
                <!-- <li><a href="#pricing">Pricing</a></li> -->
                <li>
                  <a
                    href="#faq"
                    @click="clickEvent('click', 'FAQ')"
                    v-smooth-scroll
                    >FAQ</a
                  >
                </li>
                <!-- <li><a href="#blog">Blog</a></li> -->
                <li>
                  <a
                    href="#contact-us"
                    @click="clickEvent('click', 'Contact Us')"
                    v-smooth-scroll
                    >Contact Us</a
                  >
                </li>
                <li class="no-hover">
                  <a
                    class="button"
                    style="margin: 0px 6px; background: white; color: black"
                    :href="requestDemoUrl"
                    target="_blank"
                  >
                    <span style="text-transform: none; margin: 10px 14px"
                      ><i class="ion-android-send"></i> Request Demo</span
                    ></a
                  >
                </li>
              </ul>
            </div>
            <!-- /.navbar-collapse -->
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {
      requestDemoUrl: process.env.VUE_APP_APPS_URL + "/request-demo",
    };
  },
  methods: {
    clickEvent(action, homepageSection) {
      this.$gtag.event(action, {
        homepage_section: homepageSection,
      });
    },
  },
};
</script>

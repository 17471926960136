<template>
  <div class="iq-ptb-60 green-bg iq-subscribe">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          <form class="form-inline">
            <div class="form-group">
              <label for="email">Subscribe Our Newsletter</label>
              <input
                type="email"
                class="form-control"
                placeholder="Enter Your Email"
                id="email"
                v-model="email"
              />
            </div>
            <button
              v-on:click="sendEvent"
              type="button"
              class="button bt-white re-mt-30"
            >
              Subscribe Now
            </button>
          </form>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <div
              id="subscribe-alert"
              v-if="alertMessage"
              v-bind:class="alertType"
            >
              {{ this.alertMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Header",
  components: {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        email: "",
        alertMessage: "",
        alertType: "",
        path: "/api/home-page/subscription",
      };
    },
    resetState() {
      Object.assign(this.$data, this.initialState());
    },
    sendEvent() {
      this.alertMessage = "";
      this.alertType = "";

      if (this.email === "") {
        this.alertMessage = "email is required";
        this.alertType = "text-danger";
      }

      if (this.alertMessage === "") {
        this.axios
          .post(this.$hostApi + this.path, {
            email: this.email,
          })
          .then((response) => {
            this.resetState();
            this.alertMessage = response.data;
            this.alertType = "text-success";
          })
          .catch(function (error) {
            console.log(error.response);
            alert(error.response.statusText);
          });
      }
    },
    closeAlertEvent() {
      this.alertMessage = "";
      this.alertType = "";
    },
  },
};
</script>
<template>
  <section
    id="screenshots"
    class="iq-app iq-bg iq-bg-fixed iq-font-white"
    style="background: url(assets/images/banner/bg.jpg)"
  >
    <div class="container-fluid">
      <div class="row row-eq-height">
        <div class="col-md-6 text-left iq-ptb-80 green-bg">
          <div class="iq-app-info">
            <h2 class="heading-left iq-font-white white iq-tw-6">
              App Screenshots
            </h2>
            <div class="lead iq-tw-6 iq-mb-20">
              Carbon Addons is here with its best screenshot, from this photo
              gallery you can get idea about this application.
            </div>
            <h4 class="iq-mt-50 iq-font-white iq-tw-6 iq-mb-15">
              Simple & Impactful Design
            </h4>
            <p class="">
              We design our plugin software app and dashboard system simple to
              ease the user as well as impacful for society and earth by
              developing impact dashboard to track sustainable project funded.
            </p>
          </div>
        </div>
        <div class="col-md-6 iq-app-screen iq-ptb-80">
          <div class="crsl">
            <div class="home-screen-slide"></div>
            <carousel
              :autoplay="true"
              :loop="true"
              :nav="false"
              :dots="true"
              :items="1"
              :center="true"
              :singleItem="true"
            >
              <div class="item">
                <a href="assets/images/screenshots/01.jpg" class="popup-img"
                  ><img
                    class="img-responsive"
                    src="assets/images/screenshots/01.jpg"
                    alt="#"
                /></a>
              </div>
              <div class="item">
                <a href="assets/images/screenshots/02.jpg" class="popup-img"
                  ><img
                    class="img-responsive"
                    src="assets/images/screenshots/02.jpg"
                    alt="#"
                /></a>
              </div>
              <div class="item">
                <a href="assets/images/screenshots/03.jpg" class="popup-img"
                  ><img
                    class="img-responsive"
                    src="assets/images/screenshots/03.jpg"
                    alt="#"
                /></a>
              </div>
              <div class="item">
                <a href="assets/images/screenshots/04.jpg" class="popup-img"
                  ><img
                    class="img-responsive"
                    src="assets/images/screenshots/04.jpg"
                    alt="#"
                /></a>
              </div>
              <div class="item">
                <a href="assets/images/screenshots/05.jpg" class="popup-img"
                  ><img
                    class="img-responsive"
                    src="assets/images/screenshots/05.jpg"
                    alt="#"
                /></a>
              </div>
              <div class="item">
                <a href="assets/images/screenshots/06.jpg" class="popup-img"
                  ><img
                    class="img-responsive"
                    src="assets/images/screenshots/06.jpg"
                    alt="#"
                /></a>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

 <script>
import carousel from "vue-owl-carousel";
export default {
  components: {
    carousel,
  },
  mounted() {},
};
</script>
<template>
  <div
    class="iq-ptb-70 iq-counter-box iq-bg iq-bg-fixed iq-over-black-80"
    style="background: url(assets/images/about/04.jpg)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-3 col-xs-6 text-center">
          <div class="counter">
            <img src="assets/images/app-counter/micro-climate-funds.png" style="max-width: 75px; height: 75px;" />
            <span
              class="iq-tw-6 iq-font-black"
              data-to="1620"
              data-speed="10000"
              >{{ microClimateFunds }}</span
            >
            <label class="iq-font-white">million IDR Micro-climate funds channeled</label>
          </div>
        </div>
        <div class="col-lg-3 col-sm-3 col-xs-6 text-center">
          <div class="counter">
            <img src="assets/images/app-counter/mangrove-trees-planted.png" style="max-width: 75px; height: 75px;" />
            <span
              class="iq-tw-6 iq-font-black"
              data-to="1620"
              data-speed="10000"
              >{{ mangroveTreesPlanted }}</span
            >
            <label class="iq-font-white">trees planted</label>
          </div>
        </div>
	<div class="col-lg-3 col-sm-3 col-sm-6 col-xs-6 text-center re7-mt-50">
          <div class="counter">
            <img src="assets/images/app-counter/carbon-reduction.png" style="max-width: 75px; height: 75px;" />
            <span
              class="iq-tw-6 iq-font-black"
              data-to="1620"
              data-speed="10000"
              >{{ carbonReduction }}</span
            >
            <label class="iq-font-white">{{ carbonReductionUnit }}s of <label class="iq-font-white" style="text-transform: none;">{{ carbonReductionEmission }}</label> reduction</label>
          </div>
        </div>
        <div class="col-lg-3 col-sm-3 col-sm-6 col-xs-6 text-center re7-mt-50 p-0">
          <div class="counter">
            <img src="assets/images/app-counter/communities-empowered.png" style="max-width: 75px; height: 75px;" />
            <span
              class="iq-tw-6 iq-font-black"
              data-to="1620"
              data-speed="10000"
              >{{ communitiesEmpowered }}</span
            >
            <label class="iq-font-white">local communities empowered</label>
          </div>
        </div>
	</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App_Counter",
  components: {},
  data() {
    return {
      carbonReduction: 0,
      carbonReductionEmission: '',
      carbonReductionUnit: '',
      climateProjectFunded: 0,
      mangroveTreesPlanted: 0,
      microClimateFunds: 0,
      communitiesEmpowered: 0,
      path: '/api/home-page/data/get'
    }
  },
  mounted(){
    this.get();
  },
  methods: {
    resetState() {
      Object.assign(this.$data, this.initialState());
    },
    get() {
      this.axios
        .get(this.$hostApi + this.path)
        .then((response) => {
          this.carbonReduction = response["data"]["data"]["carbon_reduction"];
          this.carbonReductionUnit = response["data"]["data"]["carbon_reduction_unit"]["name"];
          this.carbonReductionEmission = response["data"]["data"]["carbon_reduction_emission"]["symbol"];
          this.climateProjectFunded = response["data"]["data"]["climate_project_funded"];
          this.mangroveTreesPlanted = response["data"]["data"]["mangrove_trees_planted"];
          this.microClimateFunds = response["data"]["data"]["micro_climate_funds"];
          this.communitiesEmpowered = response["data"]["data"]["communities_empowered"];
        })
        .catch(function (error) {
          console.log(error)
        });
    },
    closeAlertEvent() {
      this.alertMessage = "";
      this.alertType = "";
    },
  },
};
</script>
<template>
  <div>
    <!-- <Loader></Loader> -->
    <Header></Header>
    <Banner></Banner>
    <Feature></Feature>
    <hr />
    <About></About>
    <Intro></Intro>
    <Features></Features>
    <Screen></Screen>
    <Benefit></Benefit>
    <!-- <Testimoni></Testimoni> -->
    <hr />
    <Team></Team>
    <Counter></Counter>
    <Faq></Faq>
    <!-- <Download></Download> -->
    <Pathner></Pathner>
    <Newsletter></Newsletter>
    <Contact></Contact>
    <Footer></Footer>
  </div>
</template>

<script>
// import Loader from "./components/home/Loader.vue";
import Header from "@/components/home/Header.vue";
import Banner from "@/components/home/Banner.vue";
import Feature from "@/components/home/Feature.vue";
import About from "@/components/home/About.vue";
import Intro from "@/components/home/App_intro.vue";
import Features from "@/components/home/App_features.vue";
import Screen from "@/components/home/App_screenshot.vue";
import Benefit from "@/components/home/App_benefit.vue";
// import Testimoni from "./components/home/App_testimoni.vue";
import Team from "@/components/home/Team.vue";
import Counter from "@/components/home/App_counter.vue";
import Faq from "@/components/home/App_faq.vue";
// import Download from "@/components/home/App_download.vue";
import Pathner from "@/components/home/Pathner.vue";
import Newsletter from "@/components/home/Newsletter.vue";
import Contact from "@/components/home/Contact.vue";
import Footer from "@/components/home/Footer.vue";

export default {
  name: "Home",
  components: {
    // Loader,
    Header,
    Banner,
    Feature,
    About,
    Intro,
    Features,
    Screen,
    Benefit,
    // Testimoni,
    Team,
    Counter,
    Faq,
    // Download,
    Pathner,
    Newsletter,
    Contact,
    Footer,
  },
  mounted() {
    document.title = "Carbon Addons Home Page";
    this.$gtag.pageview(window.location.href);
  },
};
</script>
